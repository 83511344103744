<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-distributor
          v-if="!isDistributor && !isDistributor_khusus"
          class="mr-2 mt-2"
          v-model:value="distributor"
        ></filter-distributor>
        <filter-brands class="mr-2 mt-2" v-model:value="brand" v-model:brand="brand" />
        <filter-products class=" mt-2" v-model:value="product" v-model:brand="brand" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-date-picker
          v-model:value="tanggal"
          placeholder="Pilih Tanggal"
          style="width: 300px"
          class="mr-2 mt-2"
          format="DD MMMM YYYY"
        />
        <a-input-search
          class="mr-2 mt-2"
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
        />
        <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>

    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right mb-2">
        <a-button
          class="ml-2"
          title="Download Excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="table-responsive">
      <md-table
        :columns="columns"
        :data-source="data"
        :row-selection="rowSelection"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        bordered
        @change="handleTableChange"
        :loading="isFetching"
      >
        ><template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #filterIcon="{ text: filtered }">
          <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template #customer="{ text }">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
          </template>
        </template>
        <template #progress="{ text: bar }">
          <div class="progress">
            <div
              :class="['progress-bar', bar.color]"
              :style="{ width: bar.value + '%' }"
              role="progressbar"
            ></div>
          </div>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #total="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #tax="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #shipping="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #status="{ text }">
          <a-tag v-if="text" color="#108ee9">Lolos</a-tag>
          <a-tag v-else color="grey">Tidak Lolos</a-tag>
        </template>
        <template #ribuan="{ text }">
          <span>{{ formatRibuan(text) }} </span>
        </template>
        <template #nilai="{ text }">
          <span>{{ text ? new Intl.NumberFormat('id-ID').format(text) : 0 }}</span>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Detail Transaksi Gudang">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="detail(record.id)"
              >
                <small>
                  <i class="fe fe-info fa-lg" />
                </small>
              </a>
            </a-tooltip>

            <a-tooltip title="Syncron">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="syncron(record.id)"
              >
                <small>
                  <i class="fa fa-refresh"></i>
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  inject,
  computed,
  nextTick,
  onMounted,
  toRefs,
  onBeforeMount,
  provide,
  watch,
} from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterBrands from '@/components/filter/FilterBrand'
import FilterProducts from '@/components/filter/FilterProduct'
import qs from 'qs'
import moment from 'moment'
import useUserInfo from '@/composables/useUserInfo'

const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Tanggal',
    dataIndex: 'tanggal',
  },
  {
    title: 'Tanggal Stok',
    dataIndex: 'tanggal_stock',
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'distri_code',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'distri_name',
  },
  {
    title: 'Kode Produk',
    dataIndex: 'product_code',
  },
  {
    title: 'Nama Produk',
    dataIndex: 'product_name',
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
  },
  {
    title: 'Stok Awal',
    dataIndex: 'stock_awal',
    slots: { customRender: 'nilai' },
  },
  {
    title: 'Sell In',
    dataIndex: 'sell_in',
    title_xls: 'Barang Masuk',
    slots: { customRender: 'nilai' },
  },
  {
    title: 'Sell Out',
    dataIndex: 'sell_out',
    title_xls: 'Barang Keluar',
    slots: { customRender: 'nilai' },
  },
  {
    title: 'Penjualan Dengan ID Toko',
    dataIndex: 'w_idbk',
    slots: { customRender: 'nilai' },
  },
  {
    title: 'Penjualan Tanpa ID Toko',
    dataIndex: 'n_idbk',
    slots: { customRender: 'nilai' },
  },
  {
    title: 'Selisih Stok',
    dataIndex: 'selisih',
    slots: { customRender: 'nilai' },
  },
]
export default defineComponent({
  name: 'MFilter',
  components: {
    FilterDistributor,
    FilterBrands,
    FilterProducts,
  },
  setup() {
    const columns = ref([])
    const data = ref([])

    columns.value = acolumns

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const searchText = ref('')
    const searchInput = ref(null)
    const current1 = ref(1)
    const q = ref('')

    const product = ref([])
    const distributor = ref([])
    const { isDistributor, vendor_id, isDistributor_khusus } = useUserInfo()
    if (isDistributor.value || isDistributor_khusus.value) {
      distributor.value.push(vendor_id.value)
    }
    const brand = ref([])
    const status = ref(null)
    const errorMessage = ref(null)

    const tanggal = ref()

    const dataProduct = ref([])
    const dataBrand = ref([])

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        product: product.value,
        brand: brand.value,
        distributor: distributor.value,
        date: tanggal.value,
        q: q.value,
        status: status.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('api/report/transaksi-distributor', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/report/transaksi-distributor', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `laporan-transaksi-distributor_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }
    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    const fetchDataProduct = products => {
      apiClient.get('/api/filter/product', { params: { q: products } }).then(response => {
        dataProduct.value = response.data
      })
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }
    onMounted(() => {
      tanggal.value = moment(new Date()).subtract(1, 'days')
      fetchData()
      fetchDataProduct()
    })

    const search = () => {
      fetchData()
    }

    const syncron = id => {
      Modal.confirm({
        title: 'Konfirmasi syncron data',
        content: 'Apakah anda ingin syncron data transaksi ?',
        onOk() {
          apiClient
            .post(`/api/synchrons/${id}`)
            .then(response => {
              const data = response.data
              console.log(data)
              formState.value = data
              console.log(formState)
            })
            .catch(e => message.error('Gagal syncron!'))
        },
        onCancel() {},
      })
    }

    return {
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      data,
      columns,
      q,
      searchText,
      searchInput,
      handleReset,
      handleSearch,
      current1,
      handleTableChange,
      startRow,
      product,
      brand,
      onSelectChange,
      state,
      fetchXlsx,
      distributor,
      fetchData,
      ...toRefs(state),
      tanggal,
      search,
      dataProduct,
      dataBrand,
      syncron,
      status,
      errorMessage,
      isDistributor,
      isDistributor_khusus,
    }
  },
})
</script>
